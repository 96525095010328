
import { reactive, defineComponent, ref, computed } from "vue";
import { FileItem } from "@/API/types";
import { message,Modal } from "ant-design-vue";
import axios from "axios";
import { uploadBatchInfo,} from "@/API/claim/delaerClaim/delaer";

//上传扩展信息共有多少个类型，对应有多少个tabs页
interface TabsType {
  key: string; //用于循环的key
  name: string; //用于显示tabs的名称
  id: string; //上传文件的id，若没上传为空
  base64code: string; //上传的文件base64
  hasFile: boolean; //是否已经上传了文件，若已经上传，直接显示
  isUpload: boolean; //用于区别与上传和提交的显示
  type: string; //上传文件的类型，用于回显
  filePath: string; //上传文件的文件路径
  total: number; //上传文件的总数
  success: number; //上传文件的成功数
  fault: number; //上传文件的失败数
  progressPercentage: string; //上传文件的步骤信息
  isFault: boolean; //是否有错误信息
  invoicePercent: number; //进度条
  invoiceProStatus: string; //上传状态
  curUploadFile: string; //文件名
  uploadFileSize: string; //总文件大小
  uploadFileCount: number; //上传文件数目
  rule: string;//规则
}

//单个文件上传的传入数保存，包含groupid，vinNo,类型
interface ItemMessageType {
    offerTypeId: string;
    programId: string;
    vinNo: string;
    retailBegin?: string;
    endTimeRetail?: string;
}
//返回的结果列表
interface Message {
    fileName: string;
    failedReason: string;
}

export default defineComponent({
emits: ["fresh-table"],
  setup(prop, ctx) {
    //是否显示抽屉
    const visible = ref(false);

    const optionMessage = ref<any>({});

    //文档命名规则
    const nameRule = ref("");

    //用于缓存初始化时从后端传进来的数据，最后确认的时候，将上传完的path合并，一起传给后端
    const cacheData = ref<any>();

    const itemMessage = reactive<ItemMessageType>({
      offerTypeId: "",
      programId: "",
      vinNo: "",
      retailBegin: "",
      endTimeRetail: "",
    });


    //批量上传总大小
    const uploadFileListSize = ref<number>(0);
    const uploadFileCount = ref<number>(0);
    const uploadfileList = ref([]);

    const isConfirm = ref(true);

    const supportTabs = ref<TabsType[]>([]);
    //当前的tabs页
    const activeTabs = ref(0);
    //表格列头
    const columns = [
      {
        title: "文件名称",
        dataIndex: "fileName",
        key: "fileName",
        width: 100,
      },
      {
        title: "上传失败原因",
        dataIndex: "failedReason",
        key: "failedReason",
        width: 100,
      },
    ];
    //表格的高度
    const tableHeight = computed(() => {
        return window.innerHeight - 500;
    });

 
    const dataSource = ref<Message[]>();

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //关闭抽屉的回调
    const afterVisibleChange = () => {
      //关闭后的事件处理
    };

    //重新上传按钮
    const isRe = ref(true);


    //多个文档上传
    const uploadFileList = (params: any, filesArray: any) => {
      debugger
      isRe.value = true;
      axios({
        method: "post",
        data: filesArray,
        url: "/claimapi/dealer/doc/uploadBatch",
        params: params,
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const complete =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            supportTabs.value[activeTabs.value].invoicePercent = complete;
            supportTabs.value[activeTabs.value].progressPercentage =
             ""+ Math.floor(
                (progressEvent.loaded / progressEvent.total) *
                  uploadFileCount.value
              ) +
              "/" +
              uploadFileCount.value +""+
              "（" +
              complete +
              "%完成）";
          }
        },
      }).then((res) => {
        isConfirm.value = false;
        isRe.value = false;
        if (res.data.code === 50061 || res.data.code === 50074) {
          message.info(res.data.message);
          // supportTabs.value[activeTabs.value].isFault = false;
        } else {
          supportTabs.value[activeTabs.value].isFault = true;
          dataSource.value = res.data.data.failedResultList;
          supportTabs.value[activeTabs.value].total = res.data.data.totalNum;
          supportTabs.value[activeTabs.value].success =
            res.data.data.successNum;
          supportTabs.value[activeTabs.value].fault = res.data.data.failedNum;
        }
      }).catch((error)=> { 
        // message.error('上传失败');
        isRe.value = false;
        });
    };

    
    // 重写默认上传方法 获取合规的文件
    let endq = true;
    const invoiceCustomRequest = (source: any) => {
              debugger
        //批量上传
        const filearray = [];
        if (!endq) {
          return;
        }
        const fordata = new FormData();
        for (let i = 0; i < uploadfileList.value.length; i++) {
          const file = uploadfileList.value[i];
          fordata.append("document", file);
          filearray.push(fordata);
        }
        const param = {
            programId: optionMessage.value.progDesignId,
            offerTypeId: optionMessage.value.offerTypeId,
            docParaSetupSupportingDocId:supportTabs.value[activeTabs.value].id
        //   programId: itemMessage.programId,
        //   offerTypeId: itemMessage.offerTypeId,
        //   claimParaSetupSupportingDocId:
        //     cacheData.value[activeTabs.value].claimParaSetupSupportingDocId,
        //   retailBegin: itemMessage.retailBegin,
        //   retailEnd: itemMessage.endTimeRetail,
        //   fileList: filearray,
        };

        endq = false;
        supportTabs.value[activeTabs.value].isUpload = true;
        uploadFileList(param, fordata);

    };
    // 上传前，多个文件上传会回调文件次数，所以用end作为标记，防止多次验证
    let end = 0;
    const beforeUpload = (file: FileItem, fileList: any) => {
        let fault = 0;
        uploadFileListSize.value = 0;
        end++;

        if (end !== fileList.length) {
          return false;
        }

        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          if (file.type === "image/jpeg" || file.type === "application/pdf") {
            if (file.size / 1024 / 1024 < 5) {
              uploadFileListSize.value =
                uploadFileListSize.value + file.size / 1024 / 1024;
            } else {
              fault = 2;
            }
          } else {
            fault = 1;
          }
        }
        if (fileList.length >100){
          message.error('传输文件不能超过100个')
          end = 0;
          return false;
        } else if (fault === 1) {
          message.error("只能上传 jpg/pdf 格式的文件");
          end = 0;
          return false;
        } else if (fault === 2) {
          message.error("文件大小不能超过5M");
          end = 0;
          return false;
        } else {
          uploadfileList.value = fileList;
          uploadFileCount.value = fileList.length;
          supportTabs.value[activeTabs.value].uploadFileSize = uploadFileListSize.value.toFixed(2)+"MB"
          end = 0;
          return true;
        }
      
    };

    //tabs页切换的事件
    const cutTabs = (index: number) => {
      activeTabs.value = index;
      // invoicePercent.value = 0;
      // invoiceProStatus.value = 'normal'
      nameRule.value = supportTabs.value[index].rule;
      endq = true;
    };

    //根据传入的参数，显示指定的上传页面内容
    const showDrawer = (option: any) => {

        console.log(option)
        optionMessage.value = option;
        // debugger
        uploadBatchInfo({ programId: option.progDesignId }).then((res: any): void => {
          console.log(res)
          supportTabs.value = [];
          for (let i=0;i<res.length;i++){
          supportTabs.value.push({
            key: i + "",
            name: res[i].tabName,
            id: res[i].docParaSetupSupportingDocId,
            base64code:"",
            hasFile: false,
            isUpload: false,
            type: "",
            filePath: "",
            total: 0,
            success: 0,
            fault: 0,
            progressPercentage: "",
            isFault: false,
            invoicePercent: 0,
            invoiceProStatus: "normal",
            curUploadFile: "",
            uploadFileSize: "",
            uploadFileCount: 0,
            rule: res[i].nameRule
          });
        }
          nameRule.value = supportTabs.value[0].rule;
          visible.value = true;
          endq = true;
        })

    //   activeTabs.value = option.message.index; //第几个tabs
    //   supportTabs.value = []; //tabs 数组
    //   itemMessage.programId = option.message.programId; //programId
    //   itemMessage.offerTypeId = option.message.offerTypeId; //类型id
    //   itemMessage.vinNo = option.message.vinNo;
    //   itemMessage.retailBegin = option.message.retailBegin;
    //   itemMessage.endTimeRetail = option.message.endTimeRetail;
    //   cacheData.value = option.data;
    //   endq = true;
    //   for (let i = 0; i < option.data.length; i++) {
    //     const one = option.data[i];
    //     supportTabs.value.push({
    //       key: i + "",
    //       name: one.tabName,
    //       id: one.id,
    //       base64code:
    //         one.ext === "jpg"
    //           ? "data:image/jpg;base64," + `${one.base64code}`
    //           : URL.createObjectURL(
    //               b64toBlob(one.base64code, "application/pdf")
    //             ),
    //       hasFile: one.ext !== "",
    //       isUpload: false,
    //       type: one.ext,
    //       filePath: one.filePath,
    //       total: 0,
    //       success: 0,
    //       fault: 0,
    //       progressPercentage: "",
    //       isFault: false,
    //       invoicePercent: 0,
    //       invoiceProStatus: "normal",
    //       curUploadFile: "",
    //       uploadFileSize: "",
    //       uploadFileCount: 0,
    //     });
    //   }
    //   visible.value = true;
    };

    //确认按钮事件
    const confirm = () => {
        //批量上传确认事件
        ctx.emit("fresh-table");
        isConfirm.value = true;
        visible.value = false;
    };
    const reUpload = ()=>{
        uploadFileListSize.value = 0;
        uploadfileList.value = [];
        uploadFileCount.value = 0;
        supportTabs.value[activeTabs.value].uploadFileSize = 0+"MB"
        supportTabs.value[activeTabs.value].hasFile = false;
        supportTabs.value[activeTabs.value].isUpload = false;

        isConfirm.value = true;
        supportTabs.value[activeTabs.value].invoicePercent = 0;
        supportTabs.value[activeTabs.value].progressPercentage = "";
        supportTabs.value[activeTabs.value].isFault = false;
        dataSource.value = [];
        endq = true;
    }

    return {
      isRe,
      reUpload,
      uploadFileCount,
      itemMessage,
      confirm,
      isConfirm,
      activeTabs,
      showDrawer,
      tableHeight,
      cutTabs,
      columns,
      dataSource,
      beforeUpload,
      invoiceCustomRequest,
      supportTabs,
      visible,
      afterVisibleChange,
      closeThisDrawer,
      nameRule
    };
  },
});
