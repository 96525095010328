
import { defineComponent, ref, computed, UnwrapRef, reactive } from "vue";
import { message ,Modal} from "ant-design-vue";
import { FileItem } from "@/API/types";
import {getDocView,getDocEdit,dealerSave,deleteFile} from "@/API/claim/delaerClaim/delaer";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import axios from "axios";
interface FormState {
  name: string;
  identityCard: string | undefined | null;
}
//上传扩展信息共有多少个类型，对应有多少个tabs页
interface TabsType {
  key: string; //用于循环的key
  name: string; //用于显示tabs的名称
  id: string; //上传文件的id，若没上传为空
  setupDocId: string;//上传文件的tab页id
  base64code: string; //上传的文件base64
  hasFile: boolean; //是否已经上传了文件，若已经上传，直接显示
  isUpload: boolean; //用于区别与上传和提交的显示
  type: string; //上传文件的类型，用于回显
  filePath: string; //上传文件的文件路径
  curUploadFile: string; //文件名
  fileName: string;
  invoicePercent: number; //进度条
  uploadFileSize: string; //总文件大小
  invoiceProStatus: string; //上传状态

  fileType: string;
  fileSize: string;
  documentId: string;

}

//扩展信息类型
interface ExtendInfoLstType {
  content: string; //显示的内容
  defaultValue: string; //默认值
  enumValue: string; //枚举类型，用，分割
  fieldNameCn: string; //字段中文名称
  fieldNameEn: string; //字段英文名称
  fieldType: string; //字段类型，共4个
  id: string;
  isMandatory: boolean; //是否必填
  maxValue: number | string; //最大值
  minValue: number | string; //最小值
  paraExtendInfoId: string;
  progDesignId: string; 
  progDesignOfferTypeId: string;
  progDesignOfferTypeName: string;
  enumValueList?: string[]; //对应的枚举值
  supportDocList?: any;//支持文档
}

export default defineComponent({
  emits: ["fresh-table"],
  setup(props,ctx) {
    const paramOption = ref<any>({});

    //上传的时候，不能保存文件
    const isUploading = ref(false);

    //pdf高度
    const pdfHeight = window.innerHeight - 450;

    //是否，只是单独预览
    const isOnlyRead = ref(true);

    //是否是新添加文档
    const isNew = ref(false);
    //是否可见抽屉
    const visible = ref(false);
    //弹出窗的标题
    const title = computed(() => {
      if (isNew.value) {
        return "新增支持文档";
      } else {
        return "编辑支持文档";
      }
    });

    //用于存储整个上传的id信息
    const id = ref("");


    //列表的内容
    const extendInfoLst = ref<ExtendInfoLstType[]>([]);

    //进度条
    // const invoicePercent = ref<number>(0);
    // const invoiceProStatus = ref<string>("normal");
    //文件信息
    const curUploadFile = ref<any>({});
    const uploadFileSize = ref<string>("");

    //tabs的默认第几个
    const activeTabs = ref("1");

    //文件上传
    const formData = new FormData();

    const supportTabs = ref<TabsType[]>([]);

    //填写的内容
    const formState: UnwrapRef<FormState> = reactive({
      name: "",
      identityCard: undefined,
    });

    //初始化弹窗
    const showDrawer = (item: any ,type: string ,option: any) => {
      paramOption.value = option;
      isUploading.value = false;
      if (type === 'view'){
        isNew.value = true;
        isOnlyRead.value = false;
        getDocView({ programId: item.groupId  }).then((res: any): void => {
          //console.log(res)

          id.value = res.id;
          const tdata = res.extendInfoLst;
          for (let i=0;i<tdata.length;i++){
            if (tdata[i].fieldType === "Enumeration"){
              tdata[i].enumValueList = tdata[i].enumValue.split(',')
            }
          }
          extendInfoLst.value = tdata;
          if (res.docList.length>0){
            supportTabs.value = [];

            for (let i=0;i<res.docList.length;i++){
              supportTabs.value.push(
                {
                  key: i+"", //用于循环的key
                  name: res.docList[i].docName, //用于显示tabs的名称
                  id: res.docList[i].collectionDocId, //上传文件的id，若没上传为空
                  base64code: "", //上传的文件base64
                  hasFile: false, //是否已经上传了文件，若已经上传，直接显示
                  isUpload: false, //用于区别与上传和提交的显示
                  type: "", //上传文件的类型，用于回显
                  filePath: "", //上传文件的文件路径
                  curUploadFile: "", //文件
                  invoicePercent: 0, //进度条
                  uploadFileSize: "", //总文件大小
                  invoiceProStatus: "normal", //上传状态
                  setupDocId:res.docList[i].setupDocId,

                  fileName:"",
                  fileType: "",
                  fileSize: "",
                  documentId: "",
                }
              )
            }
          }
          visible.value = true;
          activeTabs.value = "0";
        })
      } else {
        isNew.value = false;
        getDocEdit({ programId: item.groupId,appId:item.appId }).then((res: any): void => {
          //console.log(res)

           isOnlyRead.value = true;
          id.value = res.id;
          const tdata = res.extendInfoLst;
          for (let i=0;i<tdata.length;i++){
            if (tdata[i].fieldType === "Enumeration"){
              tdata[i].enumValueList = tdata[i].enumValue.split(',')
            }
          }
          extendInfoLst.value = tdata;
          if (res.supportDocList.length>0){
            supportTabs.value = [];
            for (let i=0;i<res.supportDocList.length;i++){
              supportTabs.value.push(
                {
                  key: i+"", //用于循环的key
                  name: res.supportDocList[i].tabName, //用于显示tabs的名称
                  id: res.supportDocList[i].docId, //上传文件的id，若没上传为空
                  base64code: res.supportDocList[i].ext === "jpg"
                    ? "data:image/jpg;base64," + `${res.supportDocList[i].base64code}`
                    : URL.createObjectURL(
                        b64toBlob(res.supportDocList[i].base64code, "application/pdf")
                      ), //上传的文件base64
                  hasFile: res.supportDocList[i].docPath.length>0?true:false, //是否已经上传了文件，若已经上传，直接显示
                  isUpload: false, //用于区别与上传和提交的显示
                  type: res.supportDocList[i].ext, //上传文件的类型，用于回显
                  filePath: res.supportDocList[i].docPath, //上传文件的文件路径
                  curUploadFile: "", //文件名
                  invoicePercent: 0, //进度条
                  uploadFileSize: "", //总文件大小
                  invoiceProStatus: "normal", //上传状态
                  setupDocId:res.supportDocList[i].setupDocId,

                  fileName:res.supportDocList[i].fileName,
                  fileType: res.supportDocList[i].fileType,
                  fileSize: res.supportDocList[i].fileSize,
                  documentId: res.supportDocList[i].documentId,
                }
              )
            }
          }
          visible.value = true;
          activeTabs.value = "0";
        })
      }
      

    };

    //tabs切换的事件
    const cutTabs = (index: string) => {
      //console.log(index);
      activeTabs.value = index;
      // invoicePercent.value = 0;
      // invoiceProStatus.value = "normal";
    };

    //单个上传文档
    const uploadFileSingle = () => {
      //上传文件单个
      isUploading.value = true;
      axios({
        method: "post",
        data: formData,
        url: "/claimapi/claimDocCollection/upload",
      }).then((res) => {
        //res
        isUploading.value = false;
        if (res.status === 200 ){
          supportTabs.value[activeTabs.value].invoicePercent = 100;
          supportTabs.value[activeTabs.value].invoiceProStatus = "success";
          // cacheData.value[activeTabs.value].filePath = res.data.data;
          supportTabs.value[activeTabs.value].filePath = res.data.data.docPath;
          supportTabs.value[activeTabs.value].fileName = res.data.data.docName;
          supportTabs.value[activeTabs.value].fileType = res.data.data.docType;
          supportTabs.value[activeTabs.value].fileSize = res.data.data.fileSize;
          supportTabs.value[activeTabs.value].documentId = res.data.data.id;


          message.success('文件上传成功');
        }
      });
    };

    // 重写默认上传方法 获取合规的文件
    const invoiceCustomRequest = (source: any) => {
      if (formData.has("document")) {
        formData.delete("document");
      }
      formData.append("document", source.file);
      supportTabs.value[activeTabs.value].curUploadFile = source.file 
      supportTabs.value[activeTabs.value].fileName = source.file.name;
      const isLt5M = source.file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("文件大小不能超过5M");
        return;
      }
      supportTabs.value[activeTabs.value].uploadFileSize =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      supportTabs.value[activeTabs.value].isUpload = true;
      uploadFileSingle();
    };
    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isJpg = file.type === 'image/jpeg';
      const isPdf = file.type === 'application/pdf';
      if (!isJpg && !isPdf) {
          message.error('jpg/pdf format files can be uploaded!')
      }
      return isJpg || isPdf
    };

    //保存事件
    const onSave = () => {
      //console.log("保存");
      let sign = 0;

      extendInfoLst.value.forEach((element) => {
        //console.log(element.content)
        if (element.isMandatory === true && (element.content === null || element.content.length < 1)) {
          message.error(`${element.fieldNameCn}为必填项目，请填写`);
          sign++;
          return;
        }

        if (element.fieldType === "Text") {
          if (
            element.content.length > (element.maxValue as number) * 1 ||
            element.content.length < (element.minValue as number) * 1
          ) {
            message.error(
              `${element.fieldNameCn}长度应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }

        if (element.fieldType === "Number") {
          const value = parseFloat(element.content);
          if (
            value >= (element.maxValue as number) * 1 ||
            value <= (element.minValue as number) * 1
          ) {
            message.error(
              `${element.fieldNameCn}数字大小应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }

        if (element.fieldType === "Date") {
          const value = Date.parse(element.content);
          if (
            value >= Date.parse(element.maxValue as string) ||
            value <= Date.parse(element.minValue as string)
          ) {
            message.error(
              `${element.fieldNameCn}日期大小应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }
      });

      if (sign !== 0){
        return;
      }

      const param = Object.assign(paramOption.value ,{id:id.value});
      //合并参数
      const supportArray: Array<any>  = [];
      const dealerExtendInfoLst: Array<any> = [];
       extendInfoLst.value.forEach((element) => {
         dealerExtendInfoLst.push({
           docParaExtendInfoId: element.paraExtendInfoId,
            content: element.content,
            id: element.id
         })
       })

      for (let i=0;i<supportTabs.value.length;i++){
        const one = supportTabs.value[i];
        supportArray.push({
          base64code: one.base64code,
          docId: one.id,
          docPath: one.filePath,
          ext: one.type,
          setupDocId: one.setupDocId,
          tabName: one.name,
          fileName:one.fileName,
          fileType:one.fileType,
          fileSize:one.fileSize,
          documentId:one.documentId,
        })
      }

       param.dealerExtendInfoLst=dealerExtendInfoLst;
       param.supportDocList=supportArray;
       isUploading.value = true;
       debugger
       dealerSave(param).then((res: any): void => {
         if (res === ""){
          ctx.emit("fresh-table");
          message.success('添加成功')
          isUploading.value = false;
          visible.value = false;
         } else {
           message.error(res)
           isUploading.value = false;
         }

       }).catch(()=>{
        message.error('数据错误')
       })
    };
    //删除现有图片重新上传
    const deleteHandle = (item: any)=>{
      Modal.confirm({
        title: '确认',
        content: '确定要删除这条支持文档吗？',
        okText: '确认',
        onOk() {
          //console.log({docId : item.id  })
          isUploading.value = true;
          deleteFile({docId : item.id  }).then((res: any): void => {
            //console.log(res)
            isUploading.value = false;
            supportTabs.value[activeTabs.value].base64code = "";
            supportTabs.value[activeTabs.value].type = "";
            supportTabs.value[activeTabs.value].filePath = "";
            supportTabs.value[activeTabs.value].hasFile = false;
            supportTabs.value[activeTabs.value].isUpload = false;

            supportTabs.value[activeTabs.value].fileName = "";
            supportTabs.value[activeTabs.value].filePath = "";
            supportTabs.value[activeTabs.value].fileSize = "";
            supportTabs.value[activeTabs.value].documentId = "";
            supportTabs.value[activeTabs.value].fileType = "";

          })

        },
        cancelText: '取消',
      });
    }
    //重新上传
    const reUpload = ()=>{
        supportTabs.value[activeTabs.value].uploadFileSize = 0+"MB"
        supportTabs.value[activeTabs.value].hasFile = false;
        supportTabs.value[activeTabs.value].isUpload = false;
        supportTabs.value[activeTabs.value].invoicePercent = 0;
        supportTabs.value[activeTabs.value].progressPercentage = "";

        supportTabs.value[activeTabs.value].fileName = "";
        supportTabs.value[activeTabs.value].filePath = "";
        supportTabs.value[activeTabs.value].fileSize = "";
        supportTabs.value[activeTabs.value].fileType = "";
        supportTabs.value[activeTabs.value].documentId = "";
    }

    return {
      reUpload,
      isUploading,
      deleteHandle,
      extendInfoLst,
      uploadFileSize,
      curUploadFile,
      // invoiceProStatus,
      // invoicePercent,
      supportTabs,
      activeTabs,
      formState,
      visible,
      title,
      isNew,
      showDrawer,
      invoiceCustomRequest,
      beforeUpload,
      cutTabs,
      onSave,
      pdfHeight,
      isOnlyRead,
    };
  },
});
