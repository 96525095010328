
import {
    computed,
    defineComponent,
    ref,
    toRefs,
    reactive,
    onMounted,
    watch,
    createVNode,
    h,
} from 'vue';
import {
    getVINTableData,
    supportReview,
    saveDealerClaimApp,
    docSample,
    validateReplaceAttributes,
} from '@/API/claim/delaerClaim/delaer';
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import { message, Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import alarm from './alarm.vue';
import supportExample from '../UploadSupport/UploadExample.vue';

export default defineComponent({
    components: {
        supportExample,
    },
    props: {
        offerTypeKey: {
            type: String,
        },
        categoryID: {
            type: String,
            required: true,
        },
        //扩展文档名称
        categoryName: {
            type: String,
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        },
        startTimeRetail: {
            type: String,
            required: true,
        },
        endTimeRetail: {
            type: String,
            required: true,
        },
        incentiveCategory: {
            type: String,
        },
        programeName: {
            type: String,
        },
        programeCode: {
            type: String,
        },
        submissionDateFrom: {
            type: String,
        },
        submissionDateTo: {
            type: String,
        },
        offerTypeNameEn: {
            type: String,
        },
    },
    emits: [
        'show-single-drawer',
        'handel-drawer',
        'change-text',
        'show-upload-support',
    ],
    setup(props, ctx) {
        //预览抽屉
        const supportExample = ref<any>(null);

        watch(
            () => props.groupId,
            () => {
                console.log('便哦');
                console.log(props.groupId);
            }
        );
        const router = useRouter();
        //当前步骤记录
        const currentStep = ref(0);
        //步骤条
        const stepArray = ref<string[]>([]);

        //隐藏显示Upload Drawer
        const showUploadDrawer = () => {
            ctx.emit('handel-drawer', {
                name: 'isShowUploadInformation',
                value: true,
            });
        };

        //是否显示筛选
        const isOpen = ref(false);
        //是否显示检查信息窗口
        const modalVisible = ref(false);
        const modalTitle = ref('');
        const modalIcon = ref<any>();
        const modalDataSource = ref<any[]>([]);
        const modalColumns = [
            {
                title: '项目编号',
                dataIndex: 'programeCode',
                key: 'programeCode',
                width: 150,
            },
            {
                title: '项目类型',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: 170,
            },
            {
                title: '客户政策预估金额',
                dataIndex: 'customerPolicyEstimatedAmount',
                key: 'customerPolicyEstimatedAmount',
                width: 170,
            },
            {
                title: '状态',
                dataIndex: 'checkStatus',
                key: 'checkStatus',
                width: 170,
            },
        ];

        //筛选条件类型接口
        interface ScreeningConditionsType {
            vinNo: null | string;
            modelName: null | string;
            retailDate: string[];
            saleDate: string[];
        }

        //筛选条件
        const screeningConditions: ScreeningConditionsType = reactive({
            vinNo: null,
            modelName: null,
            retailDate: [],
            saleDate: [],
        });

        //是否显示各种按钮
        const isButton = ref(1);

        //上传支持文档类型
        interface SupportDocVOSType {
            id: string;
            supportDocName: string;
            supportDocFilePath: string;
            isRequired: string | boolean;
        }
        //表格类型
        interface DealerVINList {
            id: string;
            vinNo: string;
            modelName: string;
            modelYear: string;
            retailDate: string;
            saleDate: string;
            financialApplicationDate: string;
            customerName: string;
            specialVehicleUse: string;
            extendedInfo: string;
            supportDocVOS: SupportDocVOSType[];
            customerPolicyEstimatedAmount: string;
            checkStatus: string;
            check: boolean;
        }
        const allData = ref<DealerVINList[]>([]);

        const dataSource = ref<DealerVINList[]>([]);

        //支持文档样例
        const supportSample = ref<any[]>([]);

        //上传扩展信息标题长度
        const supportDocSign = ref<boolean>(false);
        const supportDocVosLength = ref(150);
        //表格模拟题目
        const columns = reactive([
            // {
            //   title: "id",
            //   dataIndex: "id",
            //   key: "id",
            //   width: 120,
            // },
            {
                title: '车架号',
                dataIndex: 'vinNo',
                key: 'vinNo',
                width: 250,
                // slots: {
                //   customRender: "vinNo",
                // },
            },
            {
                title: '车型名称',
                dataIndex: 'modelName',
                key: 'modelName',
                width: 170,
            },
            {
                title: '车款',
                dataIndex: 'modelYear',
                key: 'modelYear',
                width: 120,
            },
            {
                title: '批售日期',
                dataIndex: 'saleDate',
                key: 'saleDate',
                width: 120,
            },
            {
                title: '零售日期',
                dataIndex: 'retailDate',
                key: 'retailDate',
                width: 120,
            },

            {
                title: '金融申请时间',
                dataIndex: 'financialApplicationDate',
                key: 'financialApplicationDate',
                width: 120,
            },
            {
                title: '客户名称',
                dataIndex: 'customerName',
                key: 'customerName',
                width: 120,
            },
            {
                title: '特殊车辆用途',
                dataIndex: 'specialVehicleUse',
                key: 'specialVehicleUse',
                width: 150,
            },
            {
                dataIndex: 'extendedInfo',
                key: 'extendedInfo',
                width: 120,
                slots: {
                    title: 'extendedInfoTitle',
                    customRender: 'extendedInfo',
                },
            },
            {
                // title: categoryName.value,
                dataIndex: 'supportDocVOS',
                key: 'supportDocVOS',
                // width: 120,
                width: supportDocVosLength,
                slots: {
                    title: 'supportDocVOSTitle',
                    customRender: 'supportDocVOS',
                },
            },
            {
                title: '客户政策预估金额',
                dataIndex: 'customerPolicyEstimatedAmount',
                key: 'customerPolicyEstimatedAmount',
                width: 140,
            },
            {
                title: '检查状态',
                dataIndex: 'checkStatus',
                key: 'checkStatus',
                width: 120,
                slots: {
                    customRender: 'checkStatus',
                },
            },
            {
                dataIndex: 'operation',
                key: 'operation',
                width: 130,
                fixed: 'right',
                slots: {
                    title: 'customTitle',
                    customRender: 'operation',
                },
            },
        ]);

        //动态设计表格高度
        // const tableHeight = window.innerHeight - 525;
        const noStep = ref(false);
        const tableHeight = computed(() => {
            if (noStep.value) {
                return window.innerHeight - 500;
            }

            if (isButton.value === 2 || isButton.value === 1) {
                return window.innerHeight - 535;
            } else {
                return window.innerHeight - 505;
            }
        });

        //多选框参数
        // const checkState = reactive({
        //   indeterminate: false,
        //   checkAll: false,
        // });
        const indeterminate = ref(false);
        const checkAll = ref(false);

        //所有可进行申请的条数，应该从后端数据中进行判断，这里只先进行测试先赋值
        const checkAllNum = computed(() => {
            return dataSource.value.filter(
                (item) => item.checkStatus.trim().length <= 0
            ).length;
        });
        //用于存储，table中被选中的数组
        const selectTableArray: string[] = new Array<string>();
        //用于刷新数据时，确定现在还有多少数据剩余，避免显示全部数据
        const stepTableArray = ref<string[]>([]);

        const activeOperation = ref({
            height: '0px',
            width: '0px',
        });
        //每个多选框的事件
        const checkChange = (item: any) => {
            const id = item.vinNo;
            const index = selectTableArray.indexOf(id);
            if (index > -1) {
                selectTableArray.splice(index, 1);
            } else {
                selectTableArray.push(id);
            }

            if (selectTableArray.length === 0) {
                indeterminate.value = false;
                checkAll.value = false;
                activeOperation.value = {
                    height: '0px',
                    width: '0px',
                };
            } else if (selectTableArray.length === checkAllNum.value) {
                indeterminate.value = false;
                checkAll.value = true;
                activeOperation.value = {
                    height: '15px',
                    width: '15px',
                };
            } else {
                indeterminate.value = true;
                checkAll.value = false;
                activeOperation.value = {
                    height: '7px',
                    width: '8px',
                };
            }
        };

        //多选框全选事件
        const onCheckAllChange = () => {
            //如果列表数量为0，就不能点击
            if (checkAllNum.value === 0) {
                return;
            }
            //将可添加的都放入到selectTableArray中

            if (selectTableArray.length === 0) {
                dataSource.value.forEach((item) => {
                    if (item.checkStatus.trim().length <= 0) {
                        item.check = true;
                        selectTableArray.push(item.vinNo);
                    }
                });
                //更改全选框状态
                indeterminate.value = false;
                checkAll.value = true;
                activeOperation.value = {
                    height: '15px',
                    width: '15px',
                };
            } else {
                selectTableArray.splice(0, selectTableArray.length);
                dataSource.value.forEach((item) => {
                    item.check = false;
                });
                indeterminate.value = false;
                checkAll.value = false;
                activeOperation.value = {
                    height: '0px',
                    width: '0px',
                };
            }
        };

        //重置查询条件方法
        const clearScreeningConditions = () => {
            screeningConditions.vinNo = null;
            screeningConditions.modelName = null;
            screeningConditions.retailDate = [];
            screeningConditions.saleDate = [];
        };

        //筛选表格方法，仅对前端现有数据进行查询
        const queryTableByScreeningConditions = () => {
            console.log('对现有数据进行查询');
            //screeningConditions.vinNo

            let sign = 0;
            let result = allData.value;
            if (
                screeningConditions.vinNo !== null &&
                screeningConditions.vinNo.trim() !== ''
            ) {
                const name = screeningConditions.vinNo.trim();
                result = result.filter((item) => item.vinNo.indexOf(name) > -1);
                sign++;
            }

            if (
                screeningConditions.modelName !== null &&
                screeningConditions.modelName.trim() !== ''
            ) {
                const name = screeningConditions.modelName.trim();
                result = result.filter(
                    (item) => item.modelName.indexOf(name) > -1
                );
                sign++;
            }

            if (screeningConditions.retailDate.length > 0) {
                const starttime = Date.parse(screeningConditions.retailDate[0]);
                const endtime = Date.parse(screeningConditions.retailDate[1]);
                result = result.filter(
                    (item) =>
                        Date.parse(item.retailDate) < endtime &&
                        Date.parse(item.retailDate) > starttime
                );
                sign++;
            }

            if (screeningConditions.saleDate.length > 0) {
                const starttime = Date.parse(screeningConditions.saleDate[0]);
                const endtime = Date.parse(screeningConditions.saleDate[1]);
                result = result.filter(
                    (item) =>
                        Date.parse(item.saleDate) < endtime &&
                        Date.parse(item.saleDate) > starttime
                );
                sign++;
            }

            // if (sign === 0) {
            //   result = allData.value;
            // }

            dataSource.value = result;
            dataSource.value.forEach((item) => {
                item.check = false;
            });
            indeterminate.value = false;
            checkAll.value = false;
            activeOperation.value = {
                height: '0px',
                width: '0px',
            };
            return result;
        };

        //是否高量显示扩展字段
        const extendActive = ref(true);
        const extendActiveTitle = ref(true);
        //是否是上传支持图片
        const supportActive = ref(false);

        const supportActiveTitle = ref(false);

        //显示填写扩展信息的抽屉
        const showFill = (item: DealerVINList) => {
            if (extendActive.value === false) {
                return;
            }

            let sign = true;
            if (extendActiveTitle.value === true) {
                sign = false;
            }

            ctx.emit('show-single-drawer', item, sign);
        };

        const checkDoc = async () => {
            let filterData = [];
            stepTableArray.value = [];
            //判断是否有选择，获取需要进行校验的数据
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value.filter((item) => {
                    return (
                        item.checkStatus.length <= 0 &&
                        item.extendedInfo === '1'
                    );
                });
            }

            if (filterData.length === 0) {
                message.error('没有填写扩展信息的数据，请填写后提交');
                return;
            }

            //判断选择的数据里面有没有没填写扩展信息的
            let sign = 0;
            const signArray = [];
            for (let i = 0; i < filterData.length; i++) {
                if (filterData[i].extendedInfo === '0') {
                    sign = 1;
                    if (signArray.length < 5) {
                        signArray.push(
                            h(
                                'p',
                                '车辆:' +
                                    filterData[i].vinNo +
                                    '未填写扩展信息，请补充'
                            )
                        );
                    }
                }
            }
            //如果有为填写扩展信息的，报错给提示
            // if (sign){
            //   message.error('选择的数据中，有包含未填写扩展信息的条目，请检查')
            //   return false;
            // }

            if (sign) {
                Modal.error({
                    title: '错误信息',
                    content: h('div', {}, signArray),
                    width: '500px',
                });
                return false;
            }

            //进行后端校验，预留！！！！！！！！！！！！

            const vinNoArray: Array<string> = [];

            filterData.forEach((item) => {
                vinNoArray.push(item.vinNo);
            });

            const param = {
                dealerCode: '193120340',
                offerTypeId: props.categoryID,
                progDesignId: props.groupId,
                vinNos: vinNoArray,
            };

            let checkSign = false;
            const checkArray: Array<any> = [];

            await validateReplaceAttributes(param).then((res: any): void => {
                console.log('校验2275');
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        if (checkArray.length < 5) {
                            checkArray.push(
                                h('p', res[i].vinNo + ',' + res[i].message)
                            );
                        }
                    }
                    checkSign = true;
                }
            });

            if (checkSign) {
                Modal.error({
                    title: '错误信息',
                    content: h('div', {}, checkArray),
                });

                return false;
            }

            console.log('2275通过');

            // if (selectTableArray.length !== 0) {
            //   const filterData = dataSource.value.filter(
            //     (item) => selectTableArray.indexOf(item.vinNo) > -1
            //   );

            //   dataSource.value = filterData;
            //   allData.value = JSON.parse(JSON.stringify(filterData));
            //   //重置复选框功能
            //   selectTableArray.splice(0, selectTableArray.length);
            //   checkState.indeterminate = false;
            //   checkState.checkAll = false;
            // }
            //重置所有数据的复选框状态，并保存到底去下一步有多少数据
            filterData.forEach((item) => {
                item.check = false;
                stepTableArray.value.push(item.vinNo);
            });

            //重置复选框功能，并清空保存数组
            selectTableArray.splice(0, selectTableArray.length);
            indeterminate.value = false;
            checkAll.value = false;
            //对表格重新赋值
            dataSource.value = filterData;
            allData.value = JSON.parse(JSON.stringify(filterData));

            console.log('检查完成');

            return true;
        };

        //进入到上传照片的步骤
        const goSupport = () => {
            const params = {
                programId: props.groupId,
            };
            docSample(params).then((res: any): void => {
                console.log(res);
                if (res.length > 0) {
                    supportSample.value = res;
                }

                indeterminate.value = false;
                checkAll.value = true;
                activeOperation.value = {
                    height: '15px',
                    width: '15px',
                };
                //进入到一步
                isButton.value = 2;
                console.log('进入到上传照片的步骤');
                ctx.emit('change-text', '下一步');
                //更改css状态，更改step状态

                for (let i = 0; i < stepArray.value.length; i++) {
                    if (stepArray.value[i] === '提交支持文档') {
                        currentStep.value = i;
                    }
                }

                dataSource.value.forEach((item) => {
                    item.check = true;
                    stepTableArray.value.push(item.vinNo);
                    selectTableArray.push(item.vinNo);
                });

                extendActive.value = false;
                extendActiveTitle.value = false;
                //更改supportActive状态
                supportActive.value = true;
                supportActiveTitle.value = true;
            });
        };
        //校验2276
        const checkSupport = () => {
            let filterData = [];
            //判断是否有选择
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value.filter(
                    (item) => item.checkStatus.length <= 0
                );
            }

            //对必填项进行校验
            let support = false;
            const supportArray = [];
            for (let i = 0; i < filterData.length; i++) {
                const one = filterData[i];
                for (let j = 0; j < one.supportDocVOS.length; j++) {
                    if (one.supportDocVOS[j].isRequired === 'Y') {
                        if (
                            one.supportDocVOS[j].supportDocFilePath.trim()
                                .length <= 0
                        ) {
                            // message.error('有必须填写的支持文档没有上传，不能进行下一步操作')
                            support = true;
                            if (supportArray.length < 5) {
                                supportArray.push(
                                    h(
                                        'p',
                                        one.vinNo +
                                            ',' +
                                            one.supportDocVOS[j]
                                                .supportDocName +
                                            '的文档未上传'
                                    )
                                );
                            }
                        }
                    }
                }
            }

            if (support) {
                Modal.error({
                    title: '错误信息',
                    width: '500px',
                    content: h('div', {}, supportArray),
                });

                return false;
            } else {
                dataSource.value = filterData;
                return true;
            }
        };

        //去往2277
        const goSubmit = () => {
            ctx.emit('change-text', '提交');
            isButton.value = 3;
            stepTableArray.value = [];

            indeterminate.value = false;
            checkAll.value = false;
            activeOperation.value = {
                height: '0px',
                width: '0px',
            };

            dataSource.value.forEach((item) => {
                item.check = false;
                stepTableArray.value.push(item.vinNo);
            });

            //更改css状态，更改步骤条
            for (let i = 0; i < stepArray.value.length; i++) {
                if (stepArray.value[i] === '提交') {
                    currentStep.value = i;
                }
            }
            supportActive.value = true;
            extendActive.value = true;
            extendActiveTitle.value = false;
            supportActiveTitle.value = false;
        };
        //上传数据到后端
        const onSubmit = () => {
            const parmas = [];
            let filterData = [];
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value;
            }

            filterData = filterData.filter(
                (item) => item.checkStatus.trim().length <= 0
            );

            for (let i = 0; i < filterData.length; i++) {
                const doc = filterData[i];

                const supportDocArray = [];

                if (Array.isArray(doc.supportDocVOS)) {
                    for (let j = 0; j < doc.supportDocVOS.length; j++) {
                        supportDocArray.push(doc.supportDocVOS[j].id);
                    }
                }

                debugger;
                const param = {
                    // checkResult: "",
                    // complaintStatus: "",
                    //新增的，offerTypeNameEn，programNameCn，其中programNameCn还没有，先传空
                    offerTypeNameEn: props.offerTypeNameEn,
                    programNameCn: (doc as any).programNameCn,
                    customerName: doc.customerName,
                    dealerCode: '193120340',
                    dealerNameCn: '',
                    eligiblePeriodFrom: moment(props.startTimeRetail).format(
                        'YYYY-MM-DD'
                    ),
                    eligiblePeriodTo: moment(props.endTimeRetail).format(
                        'YYYY-MM-DD'
                    ),
                    eligiblePeriodType: props.categoryID,
                    eligiblePeriodId: '',
                    firstSubmitDate: '',
                    id: '',
                    inParagraph: doc.modelYear,
                    incentiveCategory: props.incentiveCategory,
                    model: doc.modelName,
                    offerTypeId: props.categoryID,
                    offerTypeName: props.categoryName,
                    // optionName: "",
                    // payRound: "",
                    predictAmount: doc.customerPolicyEstimatedAmount,
                    price: '',
                    progDesignId: props.groupId,
                    programCode: props.programeCode,
                    programName: (doc as any).programName,
                    retailDate:
                        doc.retailDate === '' || doc.retailDate === null
                            ? ''
                            : moment(doc.retailDate).format('YYYY-MM-DD'),
                    specialUsage: doc.specialVehicleUse,
                    status: '',
                    submissionDateFrom: moment(props.submissionDateFrom).format(
                        'YYYY-MM-DD'
                    ),
                    submissionDateTo: moment(props.submissionDateTo).format(
                        'YYYY-MM-DD'
                    ),
                    typeClass: '',
                    vinNo: doc.vinNo,
                    wholesaleDate: moment(doc.saleDate).format('YYYY-MM-DD'),
                    supportDocIds: supportDocArray,
                    offerTypePathKey: props.offerTypeKey,
                };
                parmas.push(param);
            }
            // debugger

            saveDealerClaimApp(parmas).then((res: any): void => {
                console.log(res);
                if (res.length !== 0) {
                    if (res[0].checkStatus === '提交成功') {
                        message.success('提交成功，即将跳转');
                        setTimeout(() => {
                            console.log('跳转');
                            router.push({ name: 'Sales Incentive Claim' });
                        }, 1000);
                    } else {
                        for (let i = 0; i < res.length; i++) {
                            for (let j = 0; j < dataSource.value.length; j++) {
                                if (
                                    res[i].vinNo === dataSource.value[j].vinNo
                                ) {
                                    dataSource.value[j].checkStatus =
                                        res[i].checkStatus;
                                    if (selectTableArray.length !== 0) {
                                        const index = selectTableArray.indexOf(
                                            res[i].vinNo
                                        );
                                        selectTableArray.splice(index, 1);
                                    }
                                    continue;
                                }
                            }
                        }
                        message.error(
                            '校验数据中存在错误，请取消选择后重新提交'
                        );
                    }
                }
            });
        };

        //下一步操作
        const nextStep = async () => {
            //从2275 -> 2276
            if (stepArray.value.length === 3 && currentStep.value === 0) {
                const sign = await checkDoc();
                if (sign) {
                    goSupport();
                }
                return;
            }
            //从2276 -> 2277
            if (stepArray.value.length === 3 && currentStep.value === 1) {
                const sign = checkSupport();
                if (sign) {
                    ctx.emit('change-text', '提交');
                    goSubmit();
                }

                return;
            }
            //从 2275 - > 2277
            if (stepArray.value.length === 2 && currentStep.value === 0) {
                const sign = await checkDoc();
                if (sign) {
                    goSubmit();
                }
                return;
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 3 && currentStep.value === 2) {
                //提交
                onSubmit();
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 2 && currentStep.value === 1) {
                //提交
                onSubmit();
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 1 && currentStep.value === 0) {
                //提交
                onSubmit();
            }

            if (stepArray.value.length === 0) {
                //提交
                onSubmit();
            }
        };

        //根据参数获取表格数据
        const getTableData = () => {
            supportDocSign.value = false;
            clearScreeningConditions();
            const param = {
                dealerCode: '193120340',
                offerTypeKey: props.offerTypeKey,
                supportType: props.categoryID,
                // dealerCode:'193120340',
                // offerTypeKey:"Overall",
                // supportType: "8a82361b7b71010f017b7c2a054e00ea",
                programId: props.groupId,
                retailBegin: '',
                retailEnd: '',
            };
            debugger;
            getVINTableData(param)
                .then((data: any): void => {
                    if (data.length === 0) {
                        message.info('没有申报数据，请返回');
                        return;
                    }
                    debugger;

                    if (stepTableArray.value.length !== 0) {
                        const temporary = [];
                        for (let i = 0; i < data.length; i++) {
                            if (
                                stepTableArray.value.indexOf(data[i].vinNo) > -1
                            ) {
                                temporary.push(data[i]);
                            }
                        }
                        allData.value = JSON.parse(JSON.stringify(temporary));
                        dataSource.value = temporary;
                    } else {
                        allData.value = JSON.parse(JSON.stringify(data));
                        dataSource.value = data;
                    }

                    for (let i = 0; i < dataSource.value.length; i++) {
                        const data = dataSource.value[i];
                        let strLength = 0;
                        for (let j = 0; j < data.supportDocVOS.length; j++) {
                            strLength =
                                strLength +
                                data.supportDocVOS[j].supportDocName.length +
                                2;
                            supportDocSign.value = true;
                        }

                        if (strLength * 15 > supportDocVosLength.value) {
                            supportDocVosLength.value = strLength * 15;
                        }
                    }

                    stepArray.value = [];
                    //判断是否显示填写扩展信息
                    if (data.length > 0 && data[0].extendedInfo.length > 0) {
                        stepArray.value.push('填写扩展信息');
                    }
                    //判断是否显示提交支持文档
                    if (supportDocVosLength.value > 0 && supportDocSign.value) {
                        stepArray.value.push('提交支持文档');
                    }
                    //添加提交
                    if (
                        (data.length > 0 && data[0].extendedInfo.length > 0) ||
                        (supportDocVosLength.value > 0 && supportDocSign.value)
                    ) {
                        stepArray.value.push('提交');
                    } else {
                        noStep.value = true;
                    }

                    selectTableArray.splice(0, selectTableArray.length);
                    indeterminate.value = false;
                    checkAll.value = false;
                    activeOperation.value = {
                        height: '0px',
                        width: '0px',
                    };

                    if (!(data[0].extendedInfo.length > 0)) {
                        if (!supportDocSign.value) {
                            goSubmit();
                            return;
                        } else {
                            goSupport();
                            return;
                        }
                    }

                    indeterminate.value = false;
                    checkAll.value = false;
                    activeOperation.value = {
                        height: '0px',
                        width: '0px',
                    };
                })
                .catch(() => {
                    message.error('发生错误');
                });
        };
        //下载扩展信息文档
        const downloadExtendInfo = () => {
            let vinNoArray: string[] = [];

            if (selectTableArray.length === 0) {
                dataSource.value.forEach((item) => {
                    if (item.checkStatus.trim().length <= 0) {
                        vinNoArray.push(item.vinNo);
                    }
                });
            } else {
                vinNoArray = vinNoArray.concat(selectTableArray);
            }
            const downloadParams = {
                progId: props.groupId,
                vinNos: vinNoArray,
                offerTypeId: props.categoryID,
                dealerCode: '193120340',
                offerTypeKey: props.offerTypeKey,
            };

            const params = {
                url: `/claimapi/dealer/extend/templateDownload`,
                method: 'post',
                params: downloadParams,
                fileName: `模板.xlsx`,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res) => {
                    console.log(res);
                }
            );
        };

        //上传单个支持文档
        const uploadSingleSupport = (
            record: any,
            index: number,
            isOnlyRead: boolean
        ) => {
            const param = {
                vinNo: record.vinNo,
                programId: props.groupId,
                offerTypeId: props.categoryID,
                index: index,
            };

            supportReview(param).then((data: any): void => {
                //返回的数据，各类必须参数，是否是多选，是否只读，

                const filterData: Array<any> = [];

                for (let i = 0; i < data.length; i++) {
                    if (data[i].tabName.length > 0) {
                        filterData.push(data[i]);
                    }
                }

                const obj = Object.assign(
                    {
                        retailBegin: props.startTimeRetail,
                        endTimeRetail: props.endTimeRetail,
                    },
                    param
                );

                ctx.emit(
                    'show-upload-support',
                    filterData,
                    obj,
                    false,
                    isOnlyRead
                );
            });
        };

        //批量上传支持文档
        const showUploadSupportDrawer = () => {
            const param = {
                vinNo: '',
                programId: props.groupId,
                offerTypeId: props.categoryID,
                index: 0,
            };
            supportReview(param).then((data: any): void => {
                const obj = Object.assign(
                    {
                        retailBegin: props.startTimeRetail,
                        endTimeRetail: props.endTimeRetail,
                    },
                    param
                );

                ctx.emit('show-upload-support', data, obj, true, false);
            });
        };
        //预览上传文档
        const showSupportPic = (record: any, index: number) => {
            if (
                supportActive.value === true &&
                record.supportDocVOS[index].supportDocFilePath.length > 0
            ) {
                console.log('显示图片');
                uploadSingleSupport(record, index, true);
            }
        };

        //显示检查信息
        const showCheckStatus = (record: any) => {
            modalTitle.value = '车架号：' + record.vinNo;
            modalVisible.value = true;
            modalIcon.value = createVNode(alarm);
            modalDataSource.value = [
                {
                    checkStatus: record.checkStatus,
                    customerPolicyEstimatedAmount:
                        record.customerPolicyEstimatedAmount,
                    programeCode: props.programeCode,
                    categoryName: props.categoryName,
                },
            ];
        };

        //获取stepTableArray
        const getStepTableArray = () => {
            return stepTableArray.value;
        };

        //显示模板样例
        const showExample = (item: any) => {
            console.log();
            supportExample.value.showDrawer(item);
        };

        onMounted(() => {
            //根据传入的id和其他参数，获取列表信息
            getTableData();
        });

        return {
            supportExample,
            showExample,
            supportSample,
            getStepTableArray,
            stepTableArray,
            modalDataSource,
            modalColumns,
            modalIcon,
            modalTitle,
            modalVisible,
            showCheckStatus,
            showUploadSupportDrawer,
            uploadSingleSupport,
            downloadExtendInfo,
            supportActive,
            supportActiveTitle,
            showSupportPic,
            currentStep,
            nextStep,
            getTableData,
            stepArray,
            queryTableByScreeningConditions,
            showFill,
            selectTableArray,
            // ...toRefs(checkState),
            indeterminate,
            checkAll,
            checkChange,
            onCheckAllChange,
            tableHeight,
            columns,
            dataSource,
            isOpen,
            screeningConditions,
            isButton,
            clearScreeningConditions,
            extendActive,
            extendActiveTitle,
            showUploadDrawer,
            activeOperation,
        };
    },
});
