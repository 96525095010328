
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { FileItem } from "@/API/types";
import axios from "axios";
export default defineComponent({
  emits: ["close-drawer", "fresh-table"],
  props: {
  },
  // components: {
  // },
  setup(props, ctx) {

    const optionMessage = ref<any>({});

    const visible = ref(false);

    const tableHeight = window.innerHeight - 410;
    // 文件上传
    const invoiceUploadComplete = ref<boolean>(true);
    const formData = new FormData();
    const curUploadFile = ref<object>({});
    //文件大小
    const invoiceFileSize = ref<string>("");
    //进度条
    const invoicePercent = ref<number>(0);
    const invoiceProStatus = ref<string>("normal");
    //上传按钮
    const isSubmit = ref(true);

    //显示回传信息
    const dataFault = ref(false);

    //表格
    const columns = [
      {
        title: "提交结果",
        dataIndex: "message",
        key: "message",
        width: 100,
      },
    ];

    // const dataSource = [
    //   {
    //     vinNo: "vinNo",
    //     rtnMessage: "rtnMessage",
    //   },
    //   {
    //     vinNo: "vinNo",
    //     rtnMessage: "rtnMessage",
    //   },
    // ];

    interface Message {
      message: string;
    }
    const dataSource = ref<Message[]>();

    //显示抽屉时间
    const showDrawer=(item: any)=>{
        optionMessage.value = item;
        visible.value = true;
    }

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //关闭抽屉后事件
    const afterVisibleChange = () => {
      //aaa
      console.log("关闭");
      if (formData.has("file")) {
        formData.delete("file");
      }
      invoiceUploadComplete.value = true;
      isSubmit.value = true;
      invoicePercent.value = 0;
      invoiceProStatus.value = "normal";
      dataFault.value = false;
    };

    // 重写默认上传方法 获取合规的发票图片信息
    const invoiceCustomRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      invoiceFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      invoiceUploadComplete.value = false;
    };

    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("The file size should not be larger than 5MB!");
      }
      isSubmit.value = false;
      return isXlsx && isLt5M;
    };
    //上传文件
    const submitFile = () => {
      const params = {
        dealerCode: optionMessage.value.dealerCode,
        dealerNameCn: optionMessage.value.dealerNameCn,
        incentiveCategory: optionMessage.value.incentiveCategory,
        offerTypeId: optionMessage.value.offerTypeId,
        offerTypeName: optionMessage.value.offerTypeName,
        progDesignId: optionMessage.value.progDesignId,
        programCode: optionMessage.value.programCode,
        programName: optionMessage.value.programName
      };
      console.log(params);

      axios({
        method: "post",
        data: formData,
        url: "/claimapi/dealer/doc/uploadExtendInfo",
        params: params,
      }).then((res) => {
        debugger
        console.log(res);
        invoicePercent.value = 100;
        invoiceProStatus.value = "success";
        const data = res.data;
        if (data.code !== "0") {
          dataFault.value = true;

          const array: Array<any> = []

          for (let i=0;i<data.data.length;i++){
            array.push({
              message:data.data[i]
            })
          }

              //   {
    //     vinNo: "vinNo",
    //     rtnMessage: "rtnMessage",
    //   },

          dataSource.value = array;
        } else {
          message.success("上传成功");
                  ctx.emit("fresh-table");
                  visible.value = false
        }

      });
    };

    return {
        visible,
        showDrawer,
      tableHeight,
      dataFault,
      dataSource,
      columns,
      isSubmit,
      submitFile,
      invoiceFileSize,
      invoiceCustomRequest,
      curUploadFile,
      invoiceUploadComplete,
      closeThisDrawer,
      afterVisibleChange,
      beforeUpload,
      invoicePercent,
      invoiceProStatus,
    };
  },
});
