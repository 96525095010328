
import { defineComponent, ref, onMounted } from "vue";
import DealerSubmit from "./DealerSubmit/index.vue";
import DealerSubmitBefore from "./DealerSubmitBefore/index.vue";
import SingleInformation from "./DealerSubmit/ExtendInformation/SingleInformation.vue";
import UploadInformation from "./DealerSubmit/ExtendInformation/UploadInformation.vue"
import UploadSupport from "./UploadSupport/index.vue"
import { getTabsData ,avaliablePrograms} from "@/API/claim/delaerClaim/delaer";
import { useRoute ,useRouter } from 'vue-router'
import { message } from "ant-design-vue";
export default defineComponent({
  beforeRouteEnter(to, from ,next){
    if (from.fullPath !== "/claim/DealerProgramCategory"){
      next('/claim/Dealer')
    }
    next();
  },
   beforeRouteLeave(to, from, next) {
      if (to.fullPath === "/claim/DealerProgramCategory" && !('groupId' in to.params)){
        next('/claim/Dealer')
      } 
      next()
   },
  setup() {
    
    const router = useRouter();
    //零售子页面
    const dealerDom = ref<any>(null);
    //填写扩展信息子页面
    const extendInfoDom = ref<any>(null);
    //上传文档抽屉页面
    const uploadSupport = ref<any>(null)
    //隐藏下一步按钮
    const isShowNext = ref(true);
    const route = useRoute()

    //最下方按钮显示的信息
    const nextStepText = ref("下一步");

     
    
    //概览内容
    const startTime = ref(route.params.startTime);
    const endTime = ref(route.params.endTime);
    const sumNum = ref(route.params.sumNum);
    const programeCode = ref(route.params.programeCode);
    const programeName = ref(route.params.programeName);
    const selectTabs = ref("0");
    const startTimeRetail =ref(route.params.retailBegin);
    const endTimeRetail = ref(route.params.retailEnd);
    
    const groupId: any =  ref(route.params.groupId);
    const categoryName = ref(route.params.categoryName);
    const categoryID = ref(route.params.categoryID);
    const incentiveCategory = ref(route.params.incentiveCategory);
    const offerTypeNameEn = ref(route.params.offerTypeNameEn);
    const offerTypeKey = ref(route.params.offerTypeKey);
    //是否是提前提交，提前对应2278，不提前，2274-2277
    const isBefore = ref(true);
    if (route.params.isBefore === '0'){
      isBefore.value = false
    }

    interface TabsType {
      type: string;
      endtime: string;
      value: string;
      retailBegin: string;
      retailEnd: string;
      isBefore: boolean;
    }



    //tab页信息列表
    const tabs =ref<TabsType[]>([]);

    const tabBarStyle = ref({
      background: "white",
    });


    //tab页点击事件
    const cutTabs = (activeKey: string) => {
      

      avaliablePrograms({ programCode:programeCode.value,programeName:programeName.value }).then((res: any): void => {
        router.push({ name: 'DealerProgramCategory', params: {  groupId:groupId.value,programCode:programeCode.value,programName:programeName.value,declaredVin:res.program[0].declaredVin,activeTab:activeKey} })
      })

      
      // endTime.value = tabs.value[activeKey].endtime;    
      // startTimeRetail.value =tabs.value[activeKey].retailBegin;
      // endTimeRetail.value =tabs.value[activeKey].retailEnd;      
      // if ("订单合同签署日期:" ===  tabs.value[activeKey].type){
      //   console.log('go2278')
      //   nextStepText.value = '提交'
      //   isBefore.value = true;
      // } else {
      //   console.log('go2275')
      //   isBefore.value = false;
      //   nextStepText.value = '下一步'
      //   if (new Date()> new Date(Date.parse((endTime.value as string)))){
      //     console.log('隐藏下一步')
      //     isShowNext.value = false;
      //   } else {
      //      isShowNext.value = true;
      //   }
      // }
    };

    //是否显示单独填写扩展信息抽屉
    const drawerArray = ref({
      isShowUploadInformation:false
    })
    // const isShowSingleInformation = ref(false);

    //显示填写扩展信息的抽屉
    const showSingleInformation = (item: any,showButton: boolean) => {
      console.log(item);

      extendInfoDom.value.editExtend(groupId.value,item,categoryID.value,showButton);
    };

    //显示隐藏抽屉根据名称
    const showHideDrawerByName= (option: any)=>{
      console.log(option)
       drawerArray.value[option.name] = option.value;
    }

    //隐藏填写扩展信息的抽屉
    const closeDrawerByName = (drawername: string) => {
      drawerArray.value[drawername] = false;
    };

    //最底下按钮的事件
    const nextStep =()=>{
      if (isBefore.value){
        console.log('提前')
        message.success('数据保存成功');
        router.push({ name: "Sales Incentive Claim" });

      } else {
        // console.log('零售')

        dealerDom.value.nextStep();
        
      }
    }

    //刷新2275Dealer表格信息
    const freshDealerSubmitTable = ()=>{
      //刷新表格
      console.log('shuaxinbiaoge')
      dealerDom.value.getTableData();
    }

    //更改按钮文字方法
    const changeNextStepText = (value: string)=>{
      console.log('改变文字')
      nextStepText.value = value
    }
    //根据参数显示上传文档抽屉
    const showUploadSupport = (data: any,message: any, isMultiple: boolean , isOnlyRead: boolean)=>{
      let title = '上传文档';
      if (isOnlyRead){
        title = '文档预览'
      }
      
      const stepTableArray = dealerDom.value.getStepTableArray();
      
      const param = {
        title:title,
        data:data,
        isMultiple:isMultiple,
        isOnlyRead:isOnlyRead,
        message:message,
        stepTableArray:stepTableArray
      }
      uploadSupport.value.showDrawer(param)
    }

    onMounted(() => {

      getTabsData({ progDesignId: groupId.value }).then((res: any): void => {
        let typeName = "批售日期:";

        for (let i = 0; i < res.retailPeriods.length; i++) {
          if ((res.retailPeriods[i].eligiblePeriodName as string).toLowerCase().indexOf('retail')>-1){
            typeName = "零售日期:";
          }
        }
        for (let i=0;i<res.docPeriods.length;i++){

          const doc = res.docPeriods[i];

          const times = doc.eligiblePeriodFrom +" - " + doc.eligiblePeriodTo;

          tabs.value.push({endtime:doc.submissionDateTo,type:'订单合同签署日期:',value:times,            retailBegin:doc.eligiblePeriodFrom,
            retailEnd:doc.eligiblePeriodTo,isBefore:true})
        }

        for (let i=0;i<res.retailPeriods.length;i++){

          const doc = res.retailPeriods[i];

          const times = doc.eligiblePeriodFrom+" - "+doc.eligiblePeriodTo;

          tabs.value.push({endtime:doc.submissionDateTo,type:typeName,value:times,            retailBegin:doc.eligiblePeriodFrom,
            retailEnd:doc.eligiblePeriodTo,isBefore:false})
        }
        
        // selectTabs.value = router.currentRoute.query.selectTabs[]
        selectTabs.value = route.params.selectTabs as string || '0';
        isBefore.value = tabs.value[selectTabs.value].isBefore;
        
        if (isBefore.value){
          nextStepText.value = "提交"
        }

      });
    });

    return {
      offerTypeKey,
      offerTypeNameEn,
      startTime,
      changeNextStepText,
      nextStepText,
      isShowNext,
      startTimeRetail,
      endTimeRetail,
      freshDealerSubmitTable,
      extendInfoDom,
      uploadSupport,
      dealerDom,
      nextStep,
      showHideDrawerByName,
      categoryName,
      categoryID,
      selectTabs,
      closeDrawerByName,
      // isShowSingleInformation,
      drawerArray,
      groupId,
      tabs,
      tabBarStyle,
      // activeKey: ref("1"),
      cutTabs,
      endTime,
      sumNum,
      programeCode,
      programeName,
      isBefore,
      showSingleInformation,
      showUploadSupport,
      incentiveCategory
    };
  },
  components: {
    // AppleOutlined,
    DealerSubmit,
    DealerSubmitBefore,
    SingleInformation,
    UploadInformation,
    UploadSupport
  },
});
