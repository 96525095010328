
import { defineComponent, ref, onMounted } from "vue";
import {
  extendEdit,
  saveInfoExtend,
  getExtendInfo,
} from "@/API/claim/delaerClaim/delaer";
import { message } from "ant-design-vue";
export default defineComponent({
  //
  emits: ["close-drawer", "fresh-table"],

  setup(props, ctx) {
    const afterVisibleChange = (bool: boolean) => {
      console.log("visible", bool);
    };

    const groupID = ref("");
    const progDesignOfferTypeId = ref("");
    const vinNo = ref("");

    const isShow = ref(false);

    //是否显示
    const visible = ref(false);

    interface ExtendInfoType {
      content: string;
      defaultValue: string;
      enumValue: string;
      fieldNameCn: string;
      fieldNameEn: string;
      fieldType: string;
      id: string;
      isMandatory: boolean;
      maxValue: number | string;
      minValue: number | string;
      paraExtendInfoId: string;
      progDesignId: string;
      progDesignOfferTypeId: string;
      progDesignOfferTypeName: string;
      vinNo: string;
      enumValueList?: string[];
    }

    const data = ref<ExtendInfoType[]>([]);

    //限制只能输入数字和小数点和符号3
    const handleInput = (e: any) => {
      const a = e.key.replace(/[^\d.-]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    };

    interface OneItem {
      claimParaExtendInfoId: string;
      content: string;
      id: string;
    }

    interface ExpandInfo {
      progDesignId: string;
      progDesignOfferTypeId: string;
      vinNo: string;
      dealerExtendInfoLst: OneItem[];
    }

    //保存事件
    const onSave = () => {
      const param: ExpandInfo = {
        progDesignId: groupID.value,
        progDesignOfferTypeId: progDesignOfferTypeId.value,
        vinNo: vinNo.value,
        dealerExtendInfoLst: [],
      };

      let sign = 0;

      data.value.forEach((element) => {
        console.log(element.content);
        if (
          element.isMandatory === true &&
          (element.content === null || element.content.length < 1)
        ) {
          message.error(`${element.fieldNameCn}为必填项目，请填写`);
          sign++;
          return;
        }

        if (element.fieldType === "Text") {
          if (
            element.content.length > (element.maxValue as number) * 1 ||
            element.content.length < (element.minValue as number) * 1
          ) {
            message.error(
              `${element.fieldNameCn}长度应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }

        if (element.fieldType === "Number") {
          const value = parseFloat(element.content);
          if (
            value >= (element.maxValue as number) * 1 ||
            value <= (element.minValue as number) * 1
          ) {
            message.error(
              `${element.fieldNameCn}数字大小应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }

        if (element.fieldType === "Date") {
          const value = Date.parse(element.content);
          if (
            value >= Date.parse(element.maxValue as string) ||
            value <= Date.parse(element.minValue as string)
          ) {
            message.error(
              `${element.fieldNameCn}日期大小应在${element.minValue}-${element.maxValue}之间`
            );
            sign++;
            return;
          }
        }
      });

      if (sign !== 0) {
        return;
      }

      data.value.forEach((element) => {
        const one = {
          claimParaExtendInfoId: element.paraExtendInfoId,
          content: element.content,
          id: element.id,
        };
        param.dealerExtendInfoLst.push(one);
      });

      saveInfoExtend(param).then((res: any): void => {
        if (res.code === "0") {
          message.success("数据保存成功");
          visible.value = false;
          ctx.emit("fresh-table");
        } else {
          message.error(res.data[0]);
        }
      });
    };

    //关闭抽屉事件
    const closeThisDrawer = () => {
      // ctx.emit('close-drawer','isShowSingleInformation');
      visible.value = false;
      // on-colse
    };
    //编辑填写扩展信息
    const editExtend = (
      propid: string,
      item: any,
      offerTypeId: string,
      showButton: boolean
    ) => {
      groupID.value = propid;
      progDesignOfferTypeId.value = offerTypeId;
      vinNo.value = item.vinNo;

      isShow.value = showButton;

      if (item.extendedInfo === "1") {
        console.log("查看");

        const param = {
          prodId: groupID.value,
          offerTypeId: progDesignOfferTypeId.value,
          vinNo: vinNo.value,
        };
        getExtendInfo(param).then((res: any): void => {
          console.log(res);
          const tdata = res;

          for (let i = 0; i < tdata.length; i++) {
            if (tdata[i].fieldType === "Enumeration") {
              tdata[i].enumValueList = tdata[i].enumValue.split(",");
            }
          }
          data.value = tdata;
          visible.value = true;
        });
      } else {
        console.log("编辑");
        extendEdit({ prodId: propid }).then((res: any): void => {
          const tdata = res;

          for (let i = 0; i < tdata.length; i++) {
            if (tdata[i].fieldType === "Enumeration") {
              tdata[i].enumValueList = tdata[i].enumValue.split(",");
            }
          }
          data.value = tdata;
          visible.value = true;
        });
      }
    };

    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );

    console.log("spinWrapper", spinWrapper);

    return {
      isShow,
      visible,
      editExtend,
      closeThisDrawer,
      data,
      onSave,
      afterVisibleChange,
      handleInput,
      spinWrapper,
    };
  },
});
//.ant-form-item
